<template>
  <div>
    <div class="buttons">
      <button class="button is-text is-small" @click="modal = true">
        Cancel Subscription
      </button>
    </div>

    <div class="modal is-active" v-if="modal">
      <div class="modal-background" @click="modal = false"></div>
      <div class="modal-content">
        <div class="box">
          <div class="content">
            <h4>Confirm Cancellation</h4>
            <p>
              Are you sure you want to cancel your Pro Unlimited subscription?
              If you cancel, your subscription will remain active until
              {{ subEndPretty }}. After that date, you will lose access to your
              tax forms and the API.
            </p>
            <div class="notification is-danger" v-if="error">
              {{ error }}
            </div>
            <div class="buttons">
              <button
                class="button is-danger"
                :class="{ 'is-loading': processing }"
                @click="submit()"
              >
                Cancel Subscription
              </button>
              <button class="button" @click="modal = false">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { api, parseError } from "@/modules/util";

export default {
  name: "Cancel",

  props: ["user"],

  data() {
    return {
      modal: false,
      processing: false,
      error: "",
    };
  },

  computed: {
    subEndPretty() {
      return dayjs(this.user.subEnd).format("MMMM D, YYYY");
    },
  },

  methods: {
    async submit() {
      try {
        this.processing = true;
        await api.post("/users/cancel-subscription");
        this.processing = false;
        this.modal = false;
        this.$emit("init");
      } catch (err) {
        this.processing = false;
        this.error = parseError(err);
      }
    },
  },
};
</script>
