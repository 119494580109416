<template>
  <div>
    <Loading :loading="loading" />

    <h4 class="title is-4">Billing</h4>

    <AccountStatus :user="user" @init="$emit('init')" />

    <div class="box" v-show="cardEdit">
      <h6 class="title is-6">Credit Card</h6>
      <div class="columns is-variable is-1">
        <div class="column is-8">
          <div id="card-element"><!-- A Stripe Element will be inserted here. --></div>
          <p class="is-size-7" style="margin-top: 5px">
            Your card is processed securely by Stripe, Inc.
          </p>
        </div>
        <div class="column is-2">
          <button
            class="button is-fullwidth is-link"
            @click="saveCard"
            :class="{ 'is-loading': processingCard }"
          >
            Save
          </button>
        </div>
        <div class="column is-2">
          <button
            class="button is-fullwidth"
            @click="cardEdit = false"
            :disabled="processingCard"
          >
            Cancel
          </button>
        </div>
      </div>
      <p id="card-error" role="alert" class="has-text-danger">
        <!-- Display form errors. -->
      </p>
    </div>

    <div class="box" v-show="!cardEdit && user.subType != 'expired'">
      <h6 class="title is-6">Credit Card</h6>
      <div class="columns is-variable is-1">
        <div class="column is-8">
          <input class="input" type="text" :value="cardString" disabled />
          <p class="is-size-7" style="margin-top: 5px">
            Your card is processed securely by Stripe, Inc.
          </p>
        </div>
        <div class="column is-4">
          <a class="button is-fullwidth" @click="cardEdit = true"> Change Card </a>
        </div>
      </div>
      <p></p>
    </div>

    <div class="box">
      <h6 class="title is-6">Invoices</h6>
      <table class="table is-fullwidth" v-if="invoices.length">
        <thead>
          <tr>
            <th>Date <i class="fas fa-sort-down"></i></th>
            <th>Invoice No.</th>
            <th>Description</th>
            <th class="has-text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="invoice in invoices"
            :key="invoice.id"
            @click="openInvoice(invoice.hosted_invoice_url)"
          >
            <td>{{ dayjs.unix(invoice.created).format('MMMM D, YYYY') }}</td>
            <td>{{ invoice.number }}</td>
            <td>Pro Subscription</td>
            <td class="has-text-right">
              {{ `$${(invoice.amount_due / 100).toFixed(2)} USD` }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>No invoices.</p>
    </div>

    <Cancel
      v-if="['monthly', 'yearly'].includes(user.subType)"
      :user="user"
      @init="$emit('init')"
    />

    <FlashMessage :flash="flash" />
  </div>
</template>

<style scoped>
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: white;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
}

.StripeElement--focus {
  border-color: #3273dc;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.security-message {
  font-size: 0.8rem;
  color: #aaa;
}

.capitalize {
  text-transform: capitalize;
}

table.table tbody tr:hover {
  cursor: pointer;
  background-color: #fafafa;
}

table.table thead th a {
  color: #363636;
}
</style>

<script>
/* global Stripe */

import { api } from '@/modules/util'
import dayjs from 'dayjs'
import Cancel from '@/components/Cancel.vue'

export default {
  name: 'Upgrade',
  props: ['user'],
  components: { Cancel },
  data() {
    return {
      dayjs: dayjs,
      flash: [],
      // period: '',
      stripe: null,
      card: null,
      cardEdit: false,
      cardBrand: '',
      cardLast4: '',
      invoices: [],
      loading: true,
      processingCard: false,
      processingPeriod: false,
      processingCancel: false,
      showConfirmPeriod: false,
      showConfirmCancel: false,
    }
  },

  computed: {
    cardString() {
      if (this.cardBrand && this.cardLast4) {
        return `${this.cardBrand} **** **** **** ${this.cardLast4}`
      } else {
        return 'No credit card'
      }
    },
  },

  methods: {
    async init() {
      try {
        this.loading = true

        // if the user is still on a trial period, go to upgrade
        if (!this.user.subType == 'trial') {
          this.$router.push({ name: 'Upgrade' })
        }

        // set the period
        // this.period = this.user.subType

        // get the billing info
        const res = await api.get('/users/billing')
        if (res.data.cardBrand) this.cardBrand = res.data.cardBrand
        if (res.data.cardLast4) this.cardLast4 = res.data.cardLast4
        if (res.data.invoices) this.invoices = res.data.invoices

        // initialize Stripe
        this.stripe = Stripe(process.env.VUE_APP_STRIPE_PK)
        const elements = this.stripe.elements()
        this.card = elements.create('card')
        this.card.mount('#card-element')
        this.card.on('change', (e) => {
          // document.querySelector("#next-button").disabled = e.empty // TODO fix this
          document.querySelector('#card-error').textContent = e.error
            ? e.error.message
            : ''
        })

        this.loading = false
      } catch (err) {
        this.flash.push({ msg: err, type: 'error' })
        this.loading = false
      }
    },

    async saveCard() {
      try {
        this.processingCard = true
        const cardRes = await this.stripe.createToken(this.card)
        if (cardRes.error) {
          document.querySelector('#card-error').textContent = cardRes.error.message
          throw new Error('Invalid credit card.')
        }

        const changeRes = await api.post('/users/save-card', { source: cardRes.token.id })

        this.flash.push({ msg: 'Credit Card Saved' })
        this.cardBrand = changeRes.data.cardBrand
        this.cardLast4 = changeRes.data.cardLast4
        this.processingCard = false
        this.cardEdit = false
      } catch (err) {
        this.processingCard = false
        this.flash.push({ msg: err, type: 'error' })
      }
    },

    openInvoice(url) {
      window.open(url)
    },
  },
  mounted() {
    this.init()
  },
}
</script>
